@import "colors";
@import "mixins";
@import "variables";
a {
	color: $azure;
	font-weight: bold;
}

.pt-40 {
	padding-top: pxToRem(40);
}

.input-icon {
	padding: 0;
	border: pxToRem(1) solid transparent;

	.icon {
		width: pxToRem(20);
		height: pxToRem(23);
	}
}

.icon {
	&-danger * {
		fill: $error-color;
	}
}

.input-group-prepend.with-icon {
	position: relative;

	.form-control {
		width: 100%;
		padding-left: pxToRem(52);
	}

	.input-icon {
		position: absolute;
		top: 0;
		left: 0;
		bottom: pxToRem(1);
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	border-color: $vermillion;
	font-size: 0.8rem;
}

.form-label.is-invalid {
	color: $vermillion;
}

.profile-container {
	max-width: pxToRem(760);
	margin: 0 auto;
}

.h1 {
	margin-bottom: pxToRem(43);
}

/* COMPLETE PROFILE STYLES */

.landing {
	background-color: $white;
}

.workout-program-list {
	background-color: $pale-grey;

	.custom-checkbox .custom-control-label::before {
		border-radius: pxToRem(3);
		border: $default-border;
		background-color: $white;
	}

	.custom-radio .custom-control-label::before {
		border-radius: pxToRem(8);
		border: solid pxToRem(1) $border-gray;
		background-color: $white;
	}

	label {
		font-size: pxToRem(14);
		color: $cyan-blue;
	}

	.offers-filter {
		margin-bottom: 12px !important;
	}
}
.offers-filter {
	margin-top: pxToRem(10);
	margin-bottom: pxToRem(16) !important;
}

.list-container {
	margin-left: 10%;
	margin-top: -5%;
	width: 100%;
	background-color: $pale-grey;

	p {
		width: pxToRem(50);
		height: pxToRem(24);
		font-size: pxToRem(16);
		font-weight: 500;
		line-height: 1.5;
		letter-spacing: normal;
		color: $dark;
	}
	margin-right: pxToRem(20);
}

.search-input-group {
	display: flex;
	align-items: center;
	border: solid pxToRem(5) $white;
	border-radius: pxToRem(30);
	box-shadow: $default-shadow $charcoal-grey-5;
	background-color: $white;
	font-weight: normal;
	letter-spacing: normal;
	padding-left: pxToRem(8) !important;

	input {
		width: 100%;
		min-height: pxToRem(42);
		border: none !important;
		box-sizing: border-box;

		font-size: pxToRem(18);
		line-height: 1.25;
		color: $bluey-grey;
		caret-color: $bluey-grey;
		outline: none;
		padding-bottom: pxToRem(5);

		&::placeholder {
			color: $bluey-grey;
		}
	}

	.btn {
		text-align: center;
		border: solid pxToRem(1) transparent;
		border-radius: pxToRem(20);
		color: $white;
		background-image: linear-gradient(to right, $light-blue, $azure-four);
		font-size: pxToRem(15);
		line-height: 1.2;
		padding: pxToRem(11) pxToRem(30);
	}
}

.transition-none {
	transition: none !important;
}

.carousel-prev {
	transform: translateX(0);
}

.coaching-plan-level {
	display: inline-block;
	margin-right: pxToRem(20);
	font-size: pxToRem(14);
	font-weight: bold;
	line-height: 1.29;
	color: $white;

	div {
		display: flex;
		align-items: center;
	}

	img {
		width: pxToRem(32);
		margin-right: pxToRem(10);
	}
}

%border {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: pxToRem(6);
	z-index: 1;
}

/* TODO investigate */
.plan {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: pxToRem(60) pxToRem(30);
	min-width: pxToRem(320);
	cursor: pointer;

	@include respond-above(xxl) {
		max-width: pxToRem(320);
	}

	@include respond-below(md) {
		width: 100%;
		max-width: pxToRem(320);
		min-width: 0;
	}

	&-wr {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		@include respond-below(md) {
			flex-direction: column;
			align-items: center;
		}
	}

	&-content {
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.border {
		@extend %border;
		border: $default-border;
	}

	.selector {
		@extend %border;
		border: pxToRem(3) solid $algae-green;
		user-select: none;

		&-label {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			padding: pxToRem(6) pxToRem(46);
			font-size: pxToRem(15);
			font-weight: 600;
			line-height: 1.2;
			text-align: center;
			color: $white;
			background-size: 100%;
		}
	}

	&-logo {
		width: pxToRem(33);
		height: pxToRem(34);
	}

	&-title {
		margin-bottom: 0;
		font-size: pxToRem(24);
		font-weight: 600;
		line-height: 2;
		text-align: center;
	}

	&-trial {
    margin-bottom: pxToRem(24);
		padding: pxToRem(8) pxToRem(14);
		font-size: pxToRem(14);
		font-weight: bold;
		line-height: 1.14;
		text-align: center;
		color: $white;
		background-color: $algae-green;
	}

	&-price {
		font-size: pxToRem(20);
		font-weight: 500;
		line-height: 1.8;
		color: $primary-color;
	}

  &-commission {
		margin-bottom: 0;
		font-size: pxToRem(14);
		line-height: 1rem;
		font-weight: 500;
		color: $muted-color;

		span {
			font-size: pxToRem(16);
			font-weight: 500;
			line-height: 1rem;
			color: $primary-color;
		}
	}

	.buttons-toggle {
		max-width: pxToRem(161);
		margin-bottom: pxToRem(24);
	}
}

.workout-level {
	display: flex;
	justify-content: space-between;
	font-size: pxToRem(14);
	font-weight: 500;
	line-height: 1.29;
	color: $cyan-blue;

	div {
		display: flex;
		align-items: center;
	}

	img {
		width: pxToRem(18);
		margin-right: pxToRem(4);
	}
}

.buttons-toggle {
	display: flex;
	flex-direction: row;
	border-radius: pxToRem(15);
	border: $default-border;

	.toggle-option {
		padding: pxToRem(6) pxToRem(20);
		font-size: pxToRem(12);
		font-weight: 500;
		line-height: 1.5;
		letter-spacing: pxToRem(0.4);
		text-align: center;
		color: $muted-color;
		flex: 1 0;

		&.selected {
			background-color: $theme-color-1;
			border-radius: pxToRem(15);
			box-shadow: $default-shadow $azure-two-20;
			background-image: linear-gradient(to right, $light-blue, $azure-four);
			font-weight: 600;
			letter-spacing: normal;
			color: $white;
		}
	}
}

.sort-by-value {
	padding: pxToRem(10);
	border-radius: pxToRem(4);
	border: solid pxToRem(1) $border-gray;
	margin-top: pxToRem(14);
}

.experience-section {
	margin-top: pxToRem(10);
	margin-bottom: pxToRem(30) !important;

	span {
		color: $cyan-blue;
	}

	.custom-checkbox:not(:first-child) {
		margin-top: pxToRem(15);
	}
}

.search-input-group-workout {
	margin-top: pxToRem(25);
	padding-right: pxToRem(10);

  input {
    background: transparent !important;
  }
}

.filter-list {
	padding-right: pxToRem(30);
}
.mobile-filter-position{
	position: fixed;
	top: 0;
	bottom: 0;
	left: -6px;
}
.mobile-filter-list{
	position: relative;
	flex-direction: column;
	margin-bottom: 0;
	overflow-y: scroll !important;
	padding: 40px 10px 0 16px;
	background-color: $white;
	width: 280px;
	height: 100%;

	.close{
		position: absolute;
		top: 33px;
		right: 16px;
	}
	.close:focus {outline: none}
}

.mobile-filter-list::-webkit-scrollbar{@include webkit-scrollbar}
.mobile-filter-list::-webkit-scrollbar-thumb{@include webkit-scrollbar-thumb}

.equipment-filter-list {
	height: pxToRem(230);
	margin-top: pxToRem(15);
	overflow: hidden;
	overflow-y: scroll;

	table {
		margin-top: pxToRem(20);
		width: 100%;

		tr {
			margin-left: pxToRem(50);
		}

		td {
			margin-left: pxToRem(30);
		}
	}
}

.equipment-filter-list::-webkit-scrollbar {@include webkit-scrollbar}

.equipment-filter-list::-webkit-scrollbar-thumb {@include webkit-scrollbar-thumb}

.slider-duration {
	margin-top: pxToRem(35);

	input {
		margin-top: pxToRem(7);
		font-size: pxToRem(14);
		font-weight: normal;
		line-height: 1.29;
		letter-spacing: normal;
		text-align: center;
		color: $cyan-blue;
	}
}

.close.external {
	&:hover, &:active, &:focus {
		background: transparent !important;
		color: var(--secondary);
		border-color: var(--secondary);
		outline: none;
		box-shadow: none !important;
	}
}

.main-margin {
	margin-top: pxToRem(40);
	margin-left: pxToRem(40);
	margin-right: pxToRem(40);
}

.rating-text {
	margin-left: pxToRem(19);
	font-size: pxToRem(14);
	font-weight: 500;
	line-height: 1.29;
	letter-spacing: normal;
	color: $cyan-blue;

	span {
		color: $dark;
	}
}

.active-pagination-link {
	display: inline-block;
	width: pxToRem(40);
	height: pxToRem(40);
	border-radius: pxToRem(6);
	box-shadow: $default-shadow rgba(44, 155, 243, 0.2);
	background-image: linear-gradient(to right, $light-blue, $azure-four);
}

.pagination-link {
	display: inline-block;
	margin: 0 pxToRem(5);
	width: pxToRem(40);
	height: pxToRem(40);
	border-radius: pxToRem(6);
	box-shadow: $default-shadow $charcoal-grey-5;
	background-color: white;
	padding-top: pxToRem(9);
	cursor: pointer;
}

.pagination-link-text {
	text-decoration: none !important;
	text-align: center;
	font-size: pxToRem(16);
	font-weight: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: $bluey-grey;
	outline: none;
}

.active-link-text {

	font-size: pxToRem(16);
	font-weight: 600;
	line-height: 1.25;
	letter-spacing: normal;
	color: $white;
}

.pagination-gap {
	margin: 0 pxToRem(25);
	width: pxToRem(10);
	height: pxToRem(20);
	font-size: pxToRem(16);
	font-weight: normal;
	line-height: 1.25;
	letter-spacing: normal;
	color: $bluey-grey;
}

.next-pagination, .prev-pagination {
	text-decoration: none;
	display: inline-block;
	border-radius: pxToRem(6);
	box-shadow: $default-shadow $charcoal-grey-5;
	background-color: white;
	text-align: center;
	padding: pxToRem(11);
	cursor: pointer;
}

.rating-filter-block {
	margin-top: pxToRem(15) !important;
	margin-bottom: 24px;
}

.filter-title {
	font-size: pxToRem(16);
	color: $dark;
	margin: 0 !important;
}

.filter-title-slider {
	font-size: pxToRem(16);
	color: $dark;

	.filter-value {
		float: right;
		color: $cyan-blue;
	}
}

.filter-block-margin {
	margin-bottom: pxToRem(30)!important;
}

.rating-filter {
	padding-top: pxToRem(4);

	.react-rater,
	.react-rater-star {
		font-size: pxToRem(14) !important;
		color: rgba($color: $bluey-grey, $alpha: 0.3) !important;
	}
}

.cards-program {
	padding: 0 pxToRem(7) pxToRem(14) pxToRem(7);
}

.button__equipments, .button__equipments:hover{
	min-width: 104px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	font-weight: 600;
	color: $azure !important;
	border-radius: pxToRem(20);
	border: 1px solid $azure !important;
	background-color: $white !important;
	padding: 1px 12px !important;
}
.button__equipments:focus{
	outline: 0 !important;
	box-shadow: none !important;
}

.equipments-filter-list {
	position: relative;
	margin-top: pxToRem(10);

	.view-all-button {
		position: absolute;
		padding-top: pxToRem(4);
		padding-left: pxToRem(15);
		left: 63%;
		width: pxToRem(110);
		height: pxToRem(30);
		border-radius: pxToRem(15);
		border: solid pxToRem(1) $azure-border;
		font-size: pxToRem(16);
		font-weight: 600;
		line-height: normal;
		letter-spacing: normal;
		color: $azure-two;
		cursor: pointer;

		@include respond-below(xl) {
			display: block;
			left: 0;
			margin-top: pxToRem(5);
		}

		img {
			position: absolute;
			top: 25%;
			left: 80%;
		}
	}
}

.equipment-filter-list-group {
	padding-left: 0;

  .filter__checkbox {
	  font-size: .875rem;
	  color: $bluey-grey;
	  width: 50%;
	  padding-bottom: 12px;
  }
}

.filter-checkbox {
	margin-right: pxToRem(10);
	margin-bottom: pxToRem(10);
}

.program-slider {
	margin-top: pxToRem(20);
	margin-bottom: pxToRem(15);
}

.slider-block {
	margin-right: pxToRem(10);
	margin-left: pxToRem(10);
}

.program-summary {
  &-title {
    padding-right: pxToRem(25);
    padding-bottom: pxToRem(22);
    font-size: pxToRem(16);
    line-height: 1;
    color: $bluey-grey;
  }

  &-info {
    padding-bottom: pxToRem(22);
    font-size: pxToRem(16);
    line-height: 1.13;
    color: $dark;
  }
}

.body-workout-detail {
	padding-top: pxToRem(50);

	@include respond-below(xs){padding-top: 20px}

	h2 {
		font-size: pxToRem(24);
		font-weight: normal;
		line-height: 2;
		letter-spacing: normal;
		color: $dark;
	}

	p {
		font-size: pxToRem(16);
		font-weight: normal;
		line-height: 1.75;
		letter-spacing: normal;
		color: $dark;
	}

	.workout-detail-mt {
		margin-top: pxToRem(20);

		h2 {
			margin-left: pxToRem(15);
		}
	}
}

.question-count {
	height: pxToRem(16);
	font-size: pxToRem(16);
	font-weight: normal;
	line-height: 1;
	letter-spacing: normal;
	color: $cyan-blue;
}

.filter__stars{
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	.ml-1{
		margin-left: 1rem !important;
		color: $dark;
	}
}

.filter__star {
	margin-bottom: -5px;
	input{
		position: absolute;
		opacity: 0;
		z-index: -1;
	}

	.container__rating{
		font-size: 14px;
		margin-bottom: 8px;
		color: $bluey-grey;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	input:checked+.container__rating::before {
		border-color: $azure;
		background-size: 1rem;
		background-image: url("../icons/input/ic-checkbox-available.svg")
	}

	.container__rating::before {
		content: '';
		box-sizing: content-box;
		width: 1em;
		height: 1em;
		flex-shrink: 0;
		flex-grow: 0;
		border: 1px solid $bluey-grey-30;
		border-radius: 50%;
		margin-right: 0.5rem;
		margin-bottom: 4px;
		background-color: $white;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50% 50%;
	}
}

.form{
	margin: 0 !important;
}

.filter__checkbox{
	display: block;
	box-sizing: border-box;
	padding-bottom: 16px;
	margin-bottom: 0 !important;
	font-size: .875rem;
	color: $bluey-grey;
	input{
		position: absolute;
		opacity: 0;
		z-index: -1;
	}
	.plan-level-checkbox {
		display: flex;
		align-items: center;
		color: $cyan-blue;

		.text{
			text-align: center;
			display: flex;
			align-items: center;
			margin-left: 5px;
			min-height: 24px;
		}

		.img {
			display: flex;
			align-items: center;
			margin-left: pxToRem(8);
		}
	}

	.filter-checkbox {

		width: 50%;
	}

	.plan-level-checkbox::before{@include checkbox-icon(1px solid $bluey-grey-30)}

	input:checked+.plan-level-checkbox::before{
		background-size: pxToRem(14);
		background-image: url("../icons/input/ic-checkbox-active.svg")
	}
}


.consultation-filter {
	img {
		margin-right: pxToRem(8);
		padding-bottom: pxToRem(5);
	}
}

.review-mt {
	margin-top: pxToRem(10);
}

.coaching-plan-list {
	padding-bottom: pxToRem(60);

	.consultation-count-filter {
		.rating-radio {
			margin-top: pxToRem(15);
		}
	}

	.experience-section {
		margin-bottom: 0 !important;
	}
}

.schedule-title {
	margin-top: pxToRem(30);
}

.schedule-block {
	position: relative;
	width: pxToRem(230);
	height: pxToRem(190);
	border-radius: pxToRem(6);
	margin-left: pxToRem(15);
	border: $default-border;
	background-color: white;

	.week {
		height: pxToRem(30);

		.schedule-day {
			position: absolute;
			width: pxToRem(14);
			height: pxToRem(14);
			border: $default-border;
			background-color: white;
			border-radius: 50%;
			left: pxToRem(20);
			margin-top: pxToRem(9);
		}

		.active-schedule-day {
			box-shadow: 0 pxToRem(5) pxToRem(5) 0 rgba(8, 170, 245, 0.15);
			background-image: linear-gradient(to right, $light-blue, $azure-four);
		}

		.active-schedule-day:hover {
			box-shadow: pxToRem(0) pxToRem(0) pxToRem(0) pxToRem(3) rgba(8, 170, 245, 0.2);
		}
	}

	.week div:nth-child(2) {
		margin-left: pxToRem(0);
	}

	.week div:nth-child(3) {
		margin-left: pxToRem(30);
	}

	.week div:nth-child(4) {
		margin-left: pxToRem(60);
	}

	.week div:nth-child(5) {
		margin-left: pxToRem(90);
	}

	.week div:nth-child(6) {
		margin-left: pxToRem(120);
	}

	.week div:nth-child(7) {
		margin-left: pxToRem(150);
	}

	.week div:nth-child(8) {
		margin-left: pxToRem(180);
	}

	.weekdays {
		margin-left: pxToRem(23);
		margin-right: pxToRem(0);
		margin-top: pxToRem(20);
		font-size: pxToRem(12);
		font-weight: normal;
		line-height: normal;
		letter-spacing: normal;
		color: $bluey-grey;

		.day {
			padding-left: 0;
			padding-right: 0;
			width: 10% !important;
		}
	}

	hr {
		background-color: $icon-borderline;
		border: $default-border;
		width: 70%;
	}
}

.workout-list-container-modal {
	.search-input-group {
		right: pxToRem(40) !important;
	}

	.filter-list-modal {
		margin-left: pxToRem(100) !important;

		.dropdown-toggle {
			margin-right: pxToRem(40) !important;
		}
	}
}
.slider-duration {
	margin-top: pxToRem(35);

	input {
		margin-top: pxToRem(7);
		font-size: pxToRem(14);
		font-weight: normal;
		line-height: 1.29;
		letter-spacing: normal;
		text-align: center;
		color: $cyan-blue;
	}
}

.workout-list-container {
	margin: 0;
	padding: 0;
	border-top: pxToRem(1) solid $ice-blue;

	.slider-duration-item {
		width: pxToRem(250);
		height: pxToRem(115);
		padding: 0 pxToRem(20);

		.input-slider {
			width: pxToRem(20);
			height: pxToRem(40);
			border-radius: pxToRem(4);
			border: solid pxToRem(1) $border-gray;
			background-color: white;
			color: $cyan-blue;
			text-align: center;

			&::placeholder {
				color: $cyan-blue;
			}
		}

		.input-slider-second {
			width: pxToRem(60);
			height: pxToRem(40);
			border-radius: pxToRem(4);
			border: solid pxToRem(1) $border-gray;
			background-color: white;
			margin-left: pxToRem(20);
			color: $cyan-blue;
			text-align: center;
		}

		.input-duration-block {
			span {
				margin-left: pxToRem(25);
			}
		}
	}

	.slider-duration-item:hover {
		background-color: white;
	}

	.slider-experience-item {
		padding: 0 pxToRem(20);

		.experience-checkbox {
			display: flex;
		}
	}

	.slider-experience-item:hover {
		background-color: white;
	}

	.workout-list {
		padding: pxToRem(40) var(--main-indend-x);
	}

	.workout-list-modal {
		padding: pxToRem(40) pxToRem(40);
	}

	.workout-list-head {
		position: relative;
		min-height: pxToRem(60);
		background-color: white;
		padding-right: 0;
	}

	.info-icon {
		position: absolute;
		left: pxToRem(34);
		top: pxToRem(23);
	}

	.filter-icon {
		position: absolute;
		left: pxToRem(90);
		top: pxToRem(18);
	}

	.filter-icon-modal {
		position: absolute;
		left: pxToRem(40);
		top: pxToRem(18);
	}

	.filter-list {
		display: flex;
		margin-left: pxToRem(170);
		line-height: pxToRem(60);
    	flex-wrap: wrap;

		.orderby-dropdown {
			border-left: pxToRem(1) solid $ice-blue;
			margin-left: pxToRem(-10);
			padding-left: pxToRem(20);
		}

		.filter-block {
			display: flex;
			align-items: center;

			.btn-delete {
				position: relative;
				width: pxToRem(100);
				height: pxToRem(30);
				border-radius: pxToRem(15);
				border: $default-border;
				background-color: white;
				text-align: center;

				span {
					position: absolute;
					left: pxToRem(15);
					top: pxToRem(10);
					font-size: pxToRem(16);
					font-weight: normal;
					line-height: 1;
					letter-spacing: normal;
					text-align: center;
					color: $bluey-grey;
				}

				i {
					position: absolute;
					left: pxToRem(70);
					top: pxToRem(10);
				}
			}

			.search-input-group {
				width: pxToRem(237);
				border-radius: pxToRem(20);
				border: $default-border;
				height: pxToRem(40);
				padding-left: 0;
				padding-right: pxToRem(12);
				overflow: hidden;

				input {
					padding: 0 pxToRem(12);
					height: 100%;
					border: none;

					&::placeholder {
						font-size: pxToRem(14);
						line-height: pxToRem(40);
						font-weight: normal;
						letter-spacing: normal;
						color: $bluey-grey;
					}
				}
			}
		}
	}

	.duration-dropdown {
		.dropdown-toggle {
			background-color: transparent;
			box-shadow: none;
			color: black;
			border-radius: 0;

			span {
				font-size: pxToRem(16);
				font-weight: 600;
				font-style: normal;
				line-height: 1;
				letter-spacing: normal;
				color: $dark;
			}
		}

		.dropdown-toggle:focus {
			background-color: transparent;
			box-shadow: none;
			border-color: transparent;
		}

		.dropdown-toggle::after {
			margin-left: pxToRem(15);
			border: none;
			display: inline-block;
			width: pxToRem(10);
			height: pxToRem(6);
		}
	}

	.workout-list-footer {
		height: pxToRem(70);
		background-color: white;
		padding-right: 0;
		margin-right: pxToRem(-15);
	}

	.workout-equipment-filter-list {
		position: relative;
		width: pxToRem(800);
		padding-left: pxToRem(40);

		.equipment-category {
			position: relative;

			.select-all-btn {
				left: 75% !important;
				top: 5% !important;
			}
		}

		h4 {
			font-size: pxToRem(16);
			font-weight: 500;
			line-height: 1;
			letter-spacing: normal;
			color: $dark;
		}

		hr {
			border: $default-border;
			background-color: white;
			margin-left: pxToRem(-60);
			margin-right: pxToRem(-20);
		}

		.filter-list-header {
			padding-top: pxToRem(20);

			h3 {
				margin-top: pxToRem(10);
				font-size: pxToRem(18);
				font-weight: 500;
				line-height: 1;
				letter-spacing: normal;
				color: $dark;
			}

			.equipment-count {
				padding-top: pxToRem(10);
				padding-left: pxToRem(6);
				font-size: pxToRem(16);
				font-weight: normal;
				line-height: 1;
				letter-spacing: normal;
				color: $azure-two;
			}

			.confirm-btn {
				position: absolute;
				left: 85%;
				width: pxToRem(100) !important;
				height: pxToRem(26) !important;
				border-radius: pxToRem(15);
				box-shadow: 0 pxToRem(5) pxToRem(20) 0 $azure-two;
				border: none;
				background-image: linear-gradient(to right, $light-blue, $azure-four);

				span {
					position: absolute;
					top: pxToRem(10);
					left: pxToRem(30);
					font-size: pxToRem(12);
					font-weight: 600;
					letter-spacing: normal;
					text-align: center;
					color: $white;
				}
			}
		}

		.clear-btn {
			position: absolute;
			left: 25.3%;
			width: pxToRem(100);
			height: pxToRem(30);
			border-radius: pxToRem(16);
			box-shadow: $default-shadow rgba(44, 155, 243, 0.1);
			border: solid pxToRem(1) $azure-border;
			background-color: $white;

			span {
				position: absolute;
				top: pxToRem(8);
				left: pxToRem(23);
				font-size: pxToRem(14);
				font-weight: 600;
				line-height: normal;
				letter-spacing: normal;
				text-align: center;
				color: $azure-two;
			}
		}

		h4 {
			display: block;
		}

		.equipment-group {
			display: flex;
			width: 90%;
			margin-left: pxToRem(-25);
			margin-top: pxToRem(10);
			flex-wrap: wrap;

			.equipment-checkbox {
				margin-bottom: pxToRem(10);
			}
		}

		label {
			display: inline-block;
			width: pxToRem(120);
			height: pxToRem(40);
			border-radius: pxToRem(20);
			border: $default-border;
			background-color: $white;
			text-align: center;
			line-height: pxToRem(35) !important;
		}

		.experience-checkbox-checked {
			label {
				width: pxToRem(120);
				height: pxToRem(40);
				border-radius: pxToRem(20);
				background-image: linear-gradient(to right, $light-blue, $azure-four);
				color: white;
			}
		}

		label::before {
			display: none;
		}
	}
}

.active-workout-program-card {
	border: solid pxToRem(3) $azure-two !important;
}

.equipment-pop {
	width: pxToRem(420);
	height: pxToRem(166);
	background-color: $azure-two;
	overflow-y: scroll;
}

.default-template {
	margin-right: pxToRem(60) !important;
	padding-left: pxToRem(43) !important;
	min-width: pxToRem(495);

	span {
		margin-left: pxToRem(15);
		position: absolute;
		font-size: pxToRem(16);
		font-weight: 500;
		line-height: 1.13;
		letter-spacing: normal;
		color: $bluey-grey;
		margin-top: pxToRem(25);
	}

	.default-title {
		h4 {
			font-size: pxToRem(16);
			font-weight: 500;
			line-height: 1;
			letter-spacing: normal;
			color: $dark;
		}
	}

	.default-description {
		margin-top: pxToRem(0) !important;

		h5 {
			font-size: pxToRem(14);
			font-weight: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $bluey-grey;
		}
	}

	svg {
		margin-left: pxToRem(-20);
		margin-top: pxToRem(5);
	}
}

.workout-program-card {
	position: relative;
	width: 100%;
	height: pxToRem(180);
	border-radius: pxToRem(6);
	border: $default-border;
	box-shadow: $default-shadow $charcoal-grey-5;
	background-color: $white;
	margin-right: pxToRem(10);
	padding: pxToRem(30) pxToRem(40) pxToRem(0) pxToRem(20);

	&__schedule {
		font-size: pxToRem(12);
		font-weight: 600;
		line-height: 1.5;
		height: pxToRem(30);
		border-radius: pxToRem(15);
		box-shadow: 0 pxToRem(10) pxToRem(20) 0 $azure-two-20;
		background-image: linear-gradient(to right, $light-blue, $azure-four);
		padding: pxToRem(6) pxToRem(19) pxToRem(6) pxToRem(20);
	}

	.muscle-block {
		svg {
			height: pxToRem(110);
		}

		.muscles {
			display: flex;
		}
	}

	.copy-icon {
		position: absolute;
		right: pxToRem(19);
		top: pxToRem(16);
	}

	.edit-icon {
		position: absolute;
    right: pxToRem(43);
		top: pxToRem(18);
	}

	.front-body-block {
		padding-right: pxToRem(30);
	}


	.muscles-block {
		margin-top: pxToRem(-10);
		margin-bottom: pxToRem(5);
		color: $bluey-grey;
		width: pxToRem(265);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		.muscles-item {
			font-size: pxToRem(12);
			color: $bluey-grey;
		}
	}

	.workout-info {
		padding-left: 0;
		padding-right: 0;

		h4 {
			width: pxToRem(240);
			font-size: pxToRem(14);
			font-weight: 600;
			color: $dark;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.workout-modality {
			height: pxToRem(14);
			font-size: pxToRem(12);
			font-weight: 600;
			line-height: normal;
			color: $bluey-grey;
		}

		.exercise-info {
			font-size: pxToRem(12);
			font-weight: 600;
			line-height: normal;
			color: $bluey-grey;

			svg {
				position: relative;
				top: pxToRem(2);
				margin-right: pxToRem(8);
			}

			span {
				font-size: pxToRem(14);
				font-weight: normal;
				color: $dark;
				padding-top: pxToRem(5);
			}
		}
	}

	.workout-additional-info {
		position: relative;
		margin-top: pxToRem(10);
		margin-left: pxToRem(0);

		.exercise-card__equipment {
			margin-left: pxToRem(-15);
			width: 100%;
		}
	}

	.equipments-info-col {
		margin-right: pxToRem(-20);

		.equipments-info {
			position: relative;
			height: pxToRem(30);
			border-radius: pxToRem(4);
			background-color: $icon-borderline;
			text-align: center;

			.exercise-card__equipment {
				margin-right: 0 !important;
				display: flex;
				justify-content: center;
				padding-right: pxToRem(0);
			}

			img {
				position: absolute;
				left: pxToRem(10);
				top: pxToRem(9);
			}

			span {
				line-height: pxToRem(30);
				font-size: pxToRem(14);
				font-weight: 600;
				color: $bluey-grey;
			}
		}

		.equipments-btn {
			max-height: pxToRem(30) !important;
			border: none;
			padding-left: pxToRem(35);
			padding-top: 0;
			padding-bottom: 0;

			span {
				line-height: pxToRem(10) !important;
				font-size: pxToRem(14) !important;
			}
		}
	}
}

.new-exercise-level {
	.btn:not(:disabled):not(.disabled):active,
	.btn:not(:disabled):not(.disabled).active,
	.btn:not(:disabled):not(.disabled):hover {
		background-color: transparent;
		box-shadow: none;
	}

	.active {
		span {
			font-weight: bold !important;
			line-height: 1 !important;
			letter-spacing: pxToRem(0.4) !important;
			color: $white !important;
		}
	}

	.btn {
		margin: pxToRem(20) !important;
		width: pxToRem(420);
		height: pxToRem(70);
		border-radius: pxToRem(6) !important;
		border: $default-border;
		background-image: linear-gradient(to right, white, white);
		box-shadow: none;

		span {
			font-size: pxToRem(16);
			font-weight: 500;
			line-height: 1.13;
			letter-spacing: normal;
			color: $bluey-grey;
		}
	}

	.selected-beginner {
		box-shadow: $small-shadow rgba(30, 209, 72, 0.15);
		background-image: linear-gradient(to right, $algae-green, $algae-green) !important;
	}

	.selected-intermediate {
		box-shadow: $small-shadow rgba(255, 129, 17, 0.15);
		background-image: linear-gradient(to right, $orange, $orange) !important;
	}

	.selected-advanced {
		box-shadow: $small-shadow rgba(231, 59, 114, 0.05);
		background-image: linear-gradient(to right, $darkish-pink, $darkish-pink) !important;
	}
}

.difficulty-group {
	max-width: pxToRem(207);

	label {
		max-width: pxToRem(207);
	}
}

.workout-placeholder {
	min-height: pxToRem(500);
}

.initial-info-template-block {
	margin-right: pxToRem(45);
	max-height: fit-content;
}

.initial-info-template {
	position: relative;
	min-height: pxToRem(200);
	border-radius: pxToRem(6);
	box-shadow: $default-shadow $charcoal-grey-5;
	background-color: white;
	border-left: pxToRem(6) solid rgba(8, 170, 245, 0.5);
	margin-bottom: pxToRem(40);
	margin-left: pxToRem(30);
	margin-right: pxToRem(30);

	.info-icon {
		position: absolute;
		left: pxToRem(-35);
		top: pxToRem(55);
	}

	.close-block {
		position: absolute;
		top: 5%;
		left: 95%;

		span {
			font-size: pxToRem(14);
			font-weight: normal;
			line-height: normal;
			letter-spacing: normal;
			color: $bluey-grey;
		}

		svg {
			width: pxToRem(10);
			margin-left: pxToRem(10);
			cursor: pointer;
		}
	}

	.info {
		margin-left: pxToRem(122);
		margin-top: pxToRem(40);

		h4 {
			width: pxToRem(147);
			font-size: pxToRem(16);
			font-weight: 500;
			line-height: normal;
			letter-spacing: normal;
			color: $dark;
		}

		p {
			max-width: pxToRem(552);
			font-size: pxToRem(16);
			font-weight: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: $bluey-grey;
		}

		.learn-more {
			width: pxToRem(101);
			font-size: pxToRem(16);
			font-weight: 600;
			line-height: normal;
			letter-spacing: normal;
			color: $azure-two;
			margin-right: pxToRem(20);
		}

		.util-btn {
			position: relative;
			width: pxToRem(208);
			border-radius: pxToRem(20);
			border: none;
			box-shadow: $small-shadow rgba(30, 209, 72, 0.15);
			background-color: $algae-green;
			line-height: pxToRem(40);

			span {
				position: absolute;
				left: pxToRem(45);
				top: pxToRem(10);
				width: pxToRem(145);
				font-size: pxToRem(16);
				font-weight: bold;
				line-height: 1;
				letter-spacing: pxToRem(0.4);
				text-align: center;
				color: $white;
			}

			img {
				position: absolute;
				left: pxToRem(10);
				top: pxToRem(10);
			}
		}

		.info-image-block {
			display: flex;
			text-align: center;
			margin-top: pxToRem(40);

			img {
				margin-right: pxToRem(10);
				margin-bottom: pxToRem(10);
			}

			.dot {
				width: pxToRem(2);
				height: pxToRem(2);
				background-color: $algae-green;
				margin-right: pxToRem(10);
				margin-top: pxToRem(15);
			}

			.arrow {
				margin-top: pxToRem(7);
			}

			.main-icon-block {
				position: relative;

				span {
					position: absolute;
					top: pxToRem(35);
					left: pxToRem(-15);
					font-size: pxToRem(16);
					font-weight: 600;
					line-height: normal;
					letter-spacing: normal;
					text-align: center;
				}
			}
		}
	}
}

.equipment-popover {
	.popover-body {
		max-width: pxToRem(420);
		height: pxToRem(166);
		background-color: $azure-two;
		color: white;
		overflow-y: scroll;
	}

	.popover-body::-webkit-scrollbar {
		width: pxToRem(5);
	}

	.popover-body::-webkit-scrollbar-track {
		box-shadow: inset 0 0 pxToRem(2) grey;
		background: $white-30;
		border-radius: pxToRem(10);
	}

	.popover-body::-webkit-scrollbar-thumb {
		background: $white-30;
		border-radius: pxToRem(10);
	}

	.popover-body::-webkit-scrollbar-thumb:hover {
		background: $white-30;
	}

	.arrow::after,
	.bs-popover-bottom .arrow::after {
		border-top-color: $azure-two !important;
		border-bottom-color: $azure-two !important;
	}
}

.coaching-plan-marker {
	height: pxToRem(6) !important;
	border-radius: pxToRem(6) pxToRem(6) 0 0 !important;
}

.level-info {
	height: pxToRem(30);
	width: pxToRem(104);
	border-radius: pxToRem(4);
	text-align: center;

	span {
		line-height: pxToRem(30);
		font-size: pxToRem(12);
		font-weight: 600;
		font-style: normal;
		letter-spacing: normal;
		text-align: right;
		color: white;
	}
}

.level-intermediate {
	background-color: $orange;
}

.level-beginner {
	background-color: $algae-green;
}

.level-advanced {
	background-color: $darkish-pink;
}

.modal-body {
	overflow-y: auto;
	padding: 1.75rem;
}

.modal-open {
	overflow: hidden;
	overflow-y: auto;
	padding-right: 0 !important;

  .modal {
    overflow: auto;
  }
}

.empty-list-placeholder {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $bluey-grey;
	font-size: pxToRem(16);
	min-height: 35vh;

	&--lg {
		font-size: pxToRem(18);
	}
}

.bg-progress {
	background-color: $algae-green;
}

.container-fluid {
	padding-right: pxToRem(15);
	padding-left: pxToRem(15);
}

.cursor-pointer {
	cursor: pointer;
}

.trainers-list {
	.slider-price {
		margin: pxToRem(35) 0 pxToRem(20);

		&__label {
			font-size: pxToRem(16) !important;
			color: $dark !important;
		}
	}

  .pagination-block {
    margin-bottom: pxToRem(85);
    margin-top: pxToRem(40);
  }
}

.h-fit-content {
	height: fit-content;
}
.btn-load-more {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	a, a:hover{
		max-width: pxToRem(300);
		width: 100%;

		padding: pxToRem(14);
		text-align: center;
		border: pxToRem(1) solid $azure-three;
		border-radius: pxToRem(25);

		text-decoration: none;
		color: $azure;
	}
}
.empty-list-card {
  height: pxToRem(471);
  display: flex;
  justify-content: center;
  align-items: center;

	&__search{
		font-size: pxToRem(16);
		margin-bottom: pxToRem(100);
		text-align: center;
		margin-top: pxToRem(30);
		line-height: 2;
		color: $bluey-grey;
		max-width: pxToRem(250);
	}

  &__text {
    margin-top: pxToRem(30);
    font-size: pxToRem(18);
    line-height: 1;
    color: $bluey-grey;
  }
}
.m-b-2-5{
	margin-bottom: 9px;
}
.mb-0-5{
	margin-bottom: 2px;
}
.slider-price {
	margin-top: pxToRem(40);
	margin-bottom: pxToRem(30);

	&__label {
		font-size: pxToRem(16) !important;
		color: $dark !important;
	}

	.input-slider {
		width: pxToRem(60);
		height: pxToRem(40);
		border-radius: pxToRem(4);
		border: pxToRem(1) solid $border-gray;
		background-color: $white;

		&-second {
			float: right;
		}
	}
}
.rc-slider-track.rc-slider-track{
	background-color: $azure;
	height: 5px;
}

.rc-slider-handle.rc-slider-handle{
	width: pxToRem(20);
	height: pxToRem(20) ;
	margin-top: -7px;
	border: solid 2px $azure;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging {
	border-color: $azure ;
	box-shadow: 0 0 0 5px #96dbfa;
}

.rc-slider-handle:hover.rc-slider-handle:hover {
	border-color: $azure;
}
.rc-slider-handle:active.rc-slider-handle:active{
	border-color: $azure;
	box-shadow: 0 0 5px $azure;
}

.react-select.react-select {
	outline: initial;
	box-shadow: none;
	font-size: pxToRem(16);
}

.react-select__value-container.react-select__value-container {
	outline: initial;
	box-shadow: none;
	padding: 0.45rem 0.75rem 0.4rem 0.75rem;
	background: $foreground-color;
	border-radius: pxToRem(6);
}

.react-select .react-select__dropdown-indicator {
	color: $secondary-color;
}

.react-select .react-select__menu-list {
	padding-bottom: 0;
	padding-top: 0;
}

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
	color: $primary-color;
}

.react-select .react-select__dropdown-indicator,
.react-select .react-select__control--is-focused .react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select .react-select__control--is-focused .react-select__clear-indicator {
	outline: initial;
	box-shadow: initial;

	&:active,
	&:focus,
	&:hover {
		color: $azure-three !important;
	}
}

.react-select__control.react-select__control {
	border-radius: $border-radius;
	border: 1px solid $separator-color;
	background: $input-background;
	outline: initial;
	box-shadow: none;
	overflow: hidden;

	&:hover,&:focus{
		box-shadow: none;
		border-color: $separator-color;
	}
}

.react-select__indicator-separator {
	display: none;
}

.react-select__dropdown-indicator svg {
	width: pxToRem(15);
	height: pxToRem(15);
}

.react-select__option.react-select__option {
	background: $foreground-color;
	color: $primary-color;
	border-bottom: 1px solid $bluey-grey-30;

	&:last-child{
		border-bottom: none;
	}
	&:hover,
	&:active {
		background: rgba($azure-three, 0.1);
		color: $dark;
		font-weight: 600;
	}
}

.react-select__option--is-selected.react-select__option--is-selected {
	background: rgba($azure-three, 0.1);
	color: $dark;
	font-weight: 600;
}

.react-select__control--is-focused.react-select__control--is-focused {
	border-color: $theme-color-1 !important;
}

.react-select__multi-value.react-select__multi-value {
	background: transparent;
	border: 1px solid $separator-color;
}

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
	background: transparent !important;
	color: $theme-color-1 !important;
}

.react-select .react-select__menu.react-select__menu {
	z-index: 20;
	box-shadow: initial;
	border: 1px solid $theme-color-1;

	margin-top: 3px;
	background-color: $foreground-color;
	border-radius: $border-radius;
	overflow: hidden;
}

.react-select__single-value {
	padding-top: 0;
	line-height: 1.13;
	position: static;
	transform: none;
}

.search-input-equipments {
	margin-top: 40px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	border-radius: 1.875rem;
	box-shadow: 0 0.625rem 1.25rem 0 $charcoal-grey-5;
	background-color: $white;
	font-weight: 400;
	letter-spacing: normal;
	padding: .5rem!important;

	input {
		border: none;
		color: $cyan-blue;
		font-size:  1.125rem;
		&:active, &:focus{
			outline: none !important;
			color: $dark;
		}
		&::placeholder{
			color: $cyan-blue;
		}
	}
}

.error-wrapper {
	background-color: $background-color;
	display: flex;
	flex-direction: column;
}

.error-404 {
	width: pxToRem(1440);
	height: pxToRem(869);
	margin: pxToRem(60) auto;
	background-color: $white;
	border-radius: pxToRem(6);
	display: flex;
	flex-direction: column;
	align-items: center;

	.code {
		display: flex;
		align-items: center;

		font-size: pxToRem(218);
		font-weight: 500;
		line-height: pxToRem(250);
		letter-spacing: pxToRem(0.5);
		text-align: center;
		margin: pxToRem(130) 0 pxToRem(50);

		img {
			width: pxToRem(207);
			height: pxToRem(218);
			margin-right: pxToRem(24);
			margin-left: pxToRem(24);
		}
	}

	h2 {
		margin-bottom: pxToRem(40);
		font-size: pxToRem(32);
		line-height: pxToRem(48);
		letter-spacing: pxToRem(0.5);
	}

	button {
		width: pxToRem(170);
		height: pxToRem(50);
		border-radius: pxToRem(25);
		border: none;
		background: linear-gradient(110.46deg, $light-blue 32.41%, $azure-four 89.91%);
	}
}
