@import "colors", "mixins";
.l-product {
  background-color: $pale-grey;
  padding-bottom: 60px;

  &__header {
    margin-bottom: 40px;

    &__img {
      object-fit: cover;
      border-radius: 10px;
    }
    &__main {
      margin: 0 40px;
      max-width: 40%;
      .rating {
        &-count,
        &-value {
          font-size: 18px;
          line-height: 2;
          color: $dark;
        }
        &-count {
          color: $cyan-blue;
        }
      }
    }

    .label__level {
      color: $dark;
    }

    &__rating{
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: -9px;
    }

    &__title {
      font-size: 32px !important;
      line-height: 38px !important;
      font-weight: 500 !important;
      margin-bottom: 10px;

      @include multiline-truncate(3);
    }

    &__description {
      font-size: 16px;
      line-height: 28px;
      margin-top: 16px;

      &__label {
        font-size: 24px;
        line-height: 2;
      }
    }

    &__author {
      margin-top: -9px;
      color: $gray;
      font-size: 18px;
      line-height: 2;
      margin-bottom: 24px;

      a, a:hover{
        text-decoration: none;
        color: $azure;
      }
    }

    &__info {
      font-size: 18px;
      line-height: 1;
      font-weight: 500;

      .card {
        padding: 30px 25px;
        height: unset;
        border: initial;
        border-radius: .375rem;
        box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
      }
      .icon,
      img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
      }

      &__label {
        margin-top: 8px;
        margin-bottom: 24px;
        color: $cyan-blue;
      }

      .card-price {
        font-size: 32px;
        line-height: 48px;
        margin-top: 24px;

        &-old {
          font-size: 18px;
          font-weight: 400;
          line-height: 1;
          margin-left: 0;
        }
      }

      .btn {
        margin-top: 24px;
        padding: 0;
        height: 50px;
      }

      &__report {
        margin-top: 24px;
        font-size: 14px;
        line-height: 1.13;

        .report-buttons {
          color: $azure-three;
          cursor: pointer;

          span{
            margin-left: 0.625rem !important;
          }
        }
      }
    }

    @include respond-above(md) {
      &__img {
        flex: 0 0 400px;

        img {
          width: 400px;
          height: 400px;
          max-width: unset;
        }
      }

      &__main {
        flex: 1 1 auto;
      }

      &__info {
        flex: 0 0 290px;
      }
    }
  }
}

@media (max-width: 1050px) {
  .l-product {
    margin: 0 auto;

    &__header {
      margin-bottom: 40px;

      &__main {
        padding: 0 15px;
        margin: 0 auto;
        max-width: 100%;
      }
    }
  }
}

.l-equipment-item {
  display: inline-block;
  padding: 12px 17px;
  text-align: center;
  margin-right: 15px;
  margin-top: 15px;
  border-radius: 20px;
  border: 1px solid $azure-border;
  max-width: 50%;
  line-height: 1;
  font-size: 14px;
  color: $azure-two;
}

.l-wp-summary {
  .program-summary {
    &-title {
      padding-right: 5px;
      margin-bottom: 22px;
      padding-bottom: 0;
      font-size: 16px;
      color: $cyan-blue;
    }

    &-info {
      padding-bottom: 22px;
      font-size: 16px;
    }
  }
}

.workout-level-img {
  width: 1.125rem !important;
  margin-right: .3rem !important;
}

.report-text {
  font-size: 14px;
  font-weight: 400;
  margin-top: 25px;
  a {
    margin-left: 10px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    .icon {
      width: 10px;
      height: 12px;
      g {
        stroke: $azure-three !important;
      }
    }
  }
}
