@import "mixins";
@import "colors";
html,
body {
  font-family: 'Asap', sans-serif !important;
  padding: 0;
  margin: 0;
  color: $dark;
}

a {
  color: inherit;
  text-decoration: none;
}

.hidden{
  overflow: hidden !important;
}
.form-control {
  border-radius: .375rem;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 1rem;
  line-height: 1.5;
  border: 1px solid $separator-color;
  background: $input-background;
  color: $primary-color;
  height: 3.125rem;
  &::placeholder{
    color: $cyan-blue;
  }
}
.bottom-line-only {
  margin-left: -23px;
  padding:  0.75rem 0.75rem 0.75rem 3rem;
  border-radius: 0;
  border: 1px none $separator-color;
  border-bottom-style: solid;
  z-index: 0;
}
.is-invalid{
  font-size: .8rem;
  font-weight: 400;
  color: $vermillion;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.non-bc{
  z-index: 10;
  background-color: transparent !important;
}

.headers{
  height: 80px;
  background-color: $dark;
}
.consultations{
  display: flex;
  align-items: center;
  justify-content: center;
}

.error__wrapper{
  width: 100vw;
  height: 100vh;
  background-color: $white;
}
.error__content{
  display: flex;
}
.error__image{
  cursor: pointer;
  background-image: url("../icons//landing/first-column.jpg");
  width: 50vw;
  height: 100vh;
  background-size: cover;
}
.error__info{
  width: 50vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.progect__logo{
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.error__info__wrapper{
  height: 100%;
  width: 100%;
  margin: 0 auto;
  letter-spacing: normal;
  border-radius: 0.375rem;
  overflow: hidden;
  display: flex;
  max-width: 27.5rem;
  min-height: 20rem;
  padding: 3.125rem 1.875rem 2.5rem;
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
  text-align: center;
  color: $dark;
  word-wrap: break-word;
}
.ooops {
  font-size: 1.1rem;
}
.spam {
  color: $bluey-grey;
  font-size: .75rem;
  line-height: .9rem;
}
.status__code{
  font-weight: 700;
  font-size: 6rem;
}
.go__back{
  border: solid pxToRem(1) transparent;
  border-radius: 3.125rem;
  color: $white;
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: .05rem;
  font-size: 1rem;
  background-image: linear-gradient(to right, $light-blue, $azure-four);
  padding: 0.75rem 2.6rem 0.6rem;
  &:focus, :active{
    outline: none;
  }
}

@media (max-width: 768px) {
  .error__image{display: none;}
  .error__info{width: 100vw;}
}