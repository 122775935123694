@import "styles/colors";

.footer {
  background-color: $dark;
  color: $cloudy-blue;
  padding-top: 90px;
  font-size: 15px;

  .paragraph {
    line-height: 1.3rem;
    margin: 24px 0;
  }

  .stores{
    margin-top: 51px;
    display: flex;

    a{
      margin-right: 1rem;
    }
  }

  &Title {
    padding: 0;
    margin-top: 6px;
    margin-bottom: 36px;
    font-size: 16px;
    font-weight: 600;
    color: $white;
  }

  p {
    margin-top: 30px;
    font-size: 15px;
  }

  a {
    color: $cloudy-blue;
    margin-bottom: 20px;
    font-weight: normal;
  }

  &Socials {
    margin-top: 20px;
    display: flex;
    a {
      margin-right: 15px;
    }
  }

  &Copyright {
    margin-top: 115px;
    padding: 32px 0;
    background-color: $dark;
    text-align: center;
  }
}