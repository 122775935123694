@import "styles/colors";
@import "styles/mixins";
.nav__link {
  display: block;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: $white;
  text-decoration: none;
  padding: 0 pxToRem(8);
}

.nav__link:hover{
  color: $azure-border;
  text-decoration: none;
}

.btn{
  text-align: center;
  margin-left: pxToRem(20);
  border: solid pxToRem(1) transparent;
  border-radius: pxToRem(20);
  color: $white;
  font-weight: normal;
  line-height: 1.2;
  font-size: pxToRem(15);
  padding: pxToRem(11) pxToRem(30);
  box-shadow: 0 0.625rem 1.25rem 0 rgb(38 51 59 / 5%);

  &__signIn{
    background-image: linear-gradient(to right, $light-blue, $azure-four);
  }
  &__signUp{
    background-color: $dark;
    border-color: $gray;
  }
}