// Mixin to prefix several properties at once
// @author Hugo Giraudel
// @param {Map} $declarations - Declarations to prefix
// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
  }
}

// Gives a card depth effect.
// @param {Number} $depth - depth level (between 1 and 5)
// @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
// @requires {function} top-shadow
// @requires {function} bottom-shadow
@mixin depth($depth) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `card`.";
  } @else {
    box-shadow: bottom-shadow($depth), top-shadow($depth);
  }
}

// Computes a top-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}

@function top-shadow($depth) {
  $primary-offset: nth($shadowOffsetsTop, $depth) * 1px;
  $blur: nth($shadowBlursTop, $depth) * 4px;
  $color: rgba(black, nth($shadowOpacitiesTop, $depth));

  @return 0 $primary-offset $blur $color;
}

// Computes a bottom-shadow for a card effect.
// @param {Number} $depth - depth level
// @return {List}
@function bottom-shadow($depth) {
  $primary-offset: nth($shadowOffsetsBottom, $depth) * 1px;
  $blur: nth($shadowBlursBottom, $depth) * 5px;
  $color: rgba(black, nth($shadowOpacitiesBottom, $depth));
  @return 0 $primary-offset $blur $color;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

//Responsive Breakpoints

$breakpoints: (
        xxs: 420px,
        xs: 576px,
        sm: 768px,
        md: 992px,
        lg: 1200px,
        xl: 1440px,
        xxl: 2048px,
        uhd: 4096px
);

@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
                    $breakpoints,
                    $breakpoint
    ); // Write the media query.
    @media (min-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get(
                    $breakpoints,
                    $breakpoint
    ); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@function encodecolor($string) {
  @if type-of($string) == 'color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote("#{$hex}");
  }
  $string: '%23' + $string;
  @return $string;
}

@function pxToRem($value, $base: 16) {
  @return ($value / $base) * 1rem;
}

@mixin arrow($size, $color: $primary-color, $direction: "up") {
  width: 0;
  height: 0;

  @if $direction == "up" {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  } @else if $direction == "down" {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else if $direction == "right" {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if $direction == "left" {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  }
}

@mixin absolute-error() {
  position: relative;

  .text-danger {
    white-space: nowrap;
    position: absolute;
    bottom: pxToRem(-25);
  }
}

@mixin hide-number-controls {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

@mixin multiline-truncate($lines) {
  @if (type-of($lines) != 'number') {
    @error "Number of lines should be number.";
  }

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  word-break: break-word;
  hyphens: auto;
}

@mixin custom-gutter-base($gutter) {
  margin-left: -$gutter;
  margin-right: -$gutter;

  & > div {
    padding-left: $gutter;
    padding-right: $gutter;
  }
}

@mixin custom-gutter($gutter) {
  @include custom-gutter-base(pxToRem($gutter))
}

@mixin icon-fill($color) {
  path {
    fill: $color;
    @content;
  }
}

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: ($height / $width) * 100%;
    }
    > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
}

@mixin checkbox-icon($border) {
  content: '';
  display: inline-block;
  width: pxToRem(16);
  height: pxToRem(16);
  flex-shrink: 0;
  flex-grow: 0;
  border: $border;
  border-radius: 0.2em;
  margin-right: 0.5em;
  background-color: $white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

@mixin webkit-scrollbar {
  width: pxToRem(8);
  margin-top: pxToRem(10);
  opacity: 0.5;
  border-radius: pxToRem(4);
  background-color: $bluey-grey-30;
  background-clip: content-box;
}

@mixin webkit-scrollbar-thumb {
  width: pxToRem(8);
  border-radius: pxToRem(4);
  background-color: $bluey-grey;
  background-clip: content-box;
}
