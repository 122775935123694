@import "colors";
@import "mixins";
.invisible {
  visibility: hidden !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-100 {
  min-height: 100%;
}

.max-w-100 {
  max-width: 100%;
}

.text-danger {
  color: $error-color !important;
}

.text-dark {
  color: $dark;
}

.text-gray {
  color: $cyan-blue;
}

.custom-scroll {
  &::-webkit-scrollbar {
    width: pxToRem(8);
    opacity: 0.5;
    border-radius: pxToRem(4);
    background-color: $pale-grey;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb {
    width: pxToRem(8);
    border-radius: pxToRem(4);
    background-color: $cloudy-blue;
    background-clip: content-box;
  }

  &--primary {
    &::-webkit-scrollbar-thumb {
      background-color: $azure-three !important;
    }
  }
}

.m-t {
  margin-top: pxToRem(20);
}

.m-t-sm {
  margin-top: pxToRem(30);
}

.m-t-md {
  margin-top: pxToRem(50);
}

.m-r {
  margin-right: pxToRem(20);
}

.m-l {
  margin-left: pxToRem(20);
}

.m-b-md {
  margin-bottom: pxToRem(50);
}

.m-b-xl {
  margin-bottom: pxToRem(120);
}

.no-shadow {
  box-shadow: none !important;
}

.err-absolute {
  position: relative;

  .text-danger {
    white-space: nowrap;
    position: absolute;
    bottom: pxToRem(-25);
  }
}

.row-20 {
  margin-left: pxToRem(-20);
  margin-right: pxToRem(-20);

  &> div {
      padding: 0 pxToRem(20);
  }
}
.row-top-40 {
  margin-top: pxToRem(40);
}
.mt-xl-40 {
  @include respond-below (lg) {
      margin-top: pxToRem(40);
  }
}
.mt-xxl-40 {
  @include respond-below (xl) {
      margin-top: pxToRem(40);
  }
}

.overflow-hidden {
  overflow: hidden;
}

.c-pointer {
  cursor: pointer;
}

.c-forbidden {
  cursor: not-allowed !important;
}

.spacer {
  flex-grow: 1;
}

.highlighter, .rich-text {
  * {
    margin: 0;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }
}

.no-events {
  pointer-events: none;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fill-azure {
  path {
    fill: $azure-three;
  }
}

.fill-cloudy {
  path {
    fill: $cloudy-blue;
  }
}

.mt-40 {
  margin-top: pxToRem(40) !important;
}

.mb-40 {
  margin-bottom: pxToRem(40) !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.margin-x-dashboard {
  margin-left: var(--main-indend-x) !important;
  margin-right: var(--main-indend-x) !important;
}

.user-text {
  word-break: break-word;
  hyphens: auto;
}
