@import "mixins";

$azure-two: #08aaf5;
$azure-two-10: rgba($color: #08aaf5, $alpha: 0.1);
$azure: #11aaf5;
$bluey-grey: #94aab7;
$bluey-grey-30: rgba(148, 170, 183, .3);
$cloudy-blue: #aec0cd;
$cloudy-blue-50: #dadfe8;
$cyan-blue: #5E7D8F;
$steel: #7f8488;
$white-70: rgba(255, 255, 255, 0.7);
$white-30: rgba(255, 255, 255, 0.3);
$vermillion: #e02020;
$algae-green: #1ed148;
$green: #149a33;
$dark: #26333b;
$ice-blue: #f2f6f8;
$ice-blue-two: #ebeff2;
$amber: #ffb708;
$white-50: rgba(255, 255, 255, 0.5);
$darkish-pink: #e73b72;
$azure-two-20: rgba(8, 170, 245, 0.2);
$cloudy-blue-30: rgba(174, 192, 205, 0.3);
$orange: #ff8111;
$white: #ffffff;
$dark-70: rgba(38, 51, 59, 0.7);
$icon-borderline: #e4eaee;
$pale-grey: #f3f6f8;
$green: #149a33;

$gray: #788187;

$azure-three: #05a9f5;
$dark-gray: #2F3A44;

$light-blue: #64b5f6;
$azure-four: #03a9f5;
$azure-border: #2c9bf3;
$charcoal-grey-5: rgba(68, 79, 84, 0.05);

$header-white: #fefefe;

$border-gray: #dfe5e9;
$header-dark-gray: #515c62;

$transparent-green: #f4fdf6;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: $vermillion;

$separator-color-light: #f3f3f3;
$separator-color: $bluey-grey-30;
$background-color: $ice-blue;
$foreground-color: $white;
$input-background: $white;

$dark-btn-background: #575057;
$light-btn-background: #d4d4d4;

$button-text-color: $white;

$theme-color-1: $azure-two;
$theme-color-2: $steel;

$primary-color: $dark;
$secondary-color: $steel;
$muted-color: $bluey-grey;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

$dashboard-padding: pxToRem(90);
$default-shadow: 0 pxToRem(10) pxToRem(20) 0;
$small-shadow: 0 pxToRem(10) pxToRem(15) 0;

$default-border: pxToRem(1) solid $icon-borderline;
