@import "colors";
@import "mixins";
$overlay: "../../../../../assets/img/landing/img-program-overlay.svg";

.body-trainer-detail {
  margin-bottom: 50px;

  h2 {
    margin-bottom: 15px;
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }

  .but {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0 !important;

    font-weight: 400;
    font-size: pxToRem(14);
    line-height: pxToRem(16);
    color: $azure-two;

    &:active, &:focus {
      outline: none;
    }
  }
}

.landing-trainer-details {
  background-color: $white !important;

  .body-workout-detail {
    background-color: $pale-grey;
    margin-top: 0 !important;
    padding-top: 50px;
  }

  .trainer-details__bio {
    line-height: 30px;
  }

  .photos-progress {
    margin-top: 0;
  }

  .trainer-details-photos__title {
    font-size: pxToRem(36);
    font-weight: 600;

    display: flex;
    justify-content: center;
    margin-bottom: pxToRem(60);
  }

  .trainer-details__header {
    min-height: 380px;
    margin-bottom: 32px !important;

    .head-info-detail {
      min-height: inherit;
    }

    .img-trainer-thumbnail {
      object-fit: cover;
      border-radius: 50%;
      border: 10px solid $white !important;
      flex: 0 0 250px;
    }

    .head-info-detail {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .trainer-header-info {
      padding-top: 10px;
      margin-left: pxToRem(50);

      .head-info-detail {
        background-size: cover;
        opacity: 0.8;
      }

      h1 {
        padding-bottom: 0;
        font-size: 36px;
        font-weight: 500;
        line-height: 1.31;

        @include multiline-truncate(2);
      }

      .rating {
        &-text, &-count {
          font-size: 18px;
          line-height: 2;
        }
        &-count {
          color: $gray;
        }
      }
    }

    @include respond-above(md) {
      .trainer-header-info {
        max-width: 100%;
      }
    }
  }

  .trainer-rate{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .rating{
      font-size: 18px;
      line-height: 2;
      font-weight: 500;
      color: $bluey-grey;
    }
    .trainer-count-reviews{
      font-size: 18px;
      line-height: 2;
      font-weight: 500;
      color: $cyan-blue;
      margin-left: 4px;
    }
  }

}

.trainers-product-block {
  padding-bottom: 100px;
  background-color: $pale-grey !important;
  .container{
    padding-top: 100px
  }
}

.experience-block {
  margin-top: 60px;
  font-size: 16px;

  p {
    margin-bottom: 0;
  }

  &-card {
    margin-top: 20px;

    @include respond-below(md) {
      margin-left: 0;
    }

    .experience-info-img {
      width: 64px;
      height: 64px;
      border-radius: 6px;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .experience-year {
    flex: 1 1 auto;
  }

  .experience-info {
    flex: 2 1 auto;
    line-height: 1.13;
  }
}

.summary-experience {
  margin-right: 0 !important;
}

.plan-table-container-featured-text {
  margin-top: -25px !important;
  @include respond-below(xl) {
    display: none;
  }
}

.plan-table-container {
  width: 1400px;
  border-radius: 6px;
  background-color: white;
  text-align: center;
  overflow: hidden;
  margin-right: 10%;

  .col:not(:first-child) {
    padding: 15px !important;
    border-left: 1px solid #dee5e9;
  }

  .row:not(:nth-child(even)):not(:first-child) {
    background-color: $pale-grey;
  }

  .row:nth-of-type(2) {
    .col:first-child {
      border-top: 1px solid #dee5e9;
    }
  }

  .row:last-of-type {
    background: $white !important;
  }

  .plan-table-header {
    width: 50px;
    height: 140px;
    opacity: 0.8;
    background-size: cover;
    border-left: none !important;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 2;
    font-weight: bold;
    color: $white;

    img {
      width: 48px;
      height: 48px;
    }
  }

  .plan-title {
    display: flex;
    border-top: pxToRem(1) solid #dee5e9;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 0;
    overflow: hidden;

    &_table {
      line-height: pxToRem(24);
      font-size: pxToRem(16);
    }

    span {
      position: relative;
      height: 49px;
      font-size: 16px !important;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: $dark;
    }
  }

  .plan-table-title {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: pxToRem(40);

    font-size: pxToRem(16);
    line-height: 1.25;
    color: $gray;

    &__icon-info {
      width: 20px;
      margin: 0 0 0 5px;
      display: inline-block !important;
    }

    .plan-title-header {
      position: absolute;
      top: 50%;
    }
  }

  .consultations-label {
    padding: 7px 8px;
    border-radius: 6px;
    background-color: $white;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: $bluey-grey;

    .icon {
      width: 18px;
      height: 18px;
      margin-right: 6px;

      @include icon-fill($algae-green);
    }
  }

  .purchase-btn {
    color: $azure-border;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 200px;
    height: 50px;
    border-radius: 25px;
    border: solid 1px $azure-border;
    cursor: pointer;
    padding: 0;
    font-weight: 500;
    &:hover{
      text-decoration: none;
      color: $white;
      background-color: $azure-border;
      transition: 0.3s;
    }
    &:active, &:focus{
      outline: none;
    }
  }

  .rating {
    display: flex;
    justify-content: center;
    align-items: center;

    &-text {
      font-size: 14px;
      margin-left: 11px;
    }
  }

  .plan-price {
    font-size: 21px;
    font-weight: 500;
  }

  .plan-month {
    font-size: 14px;
    color: $bluey-grey;
  }

  .plan-free {
    color: $algae-green;
  }

  a.text-dark {
    font-size: 16px;
    font-weight: 500;
    &:hover{
      text-decoration: none;
    }
  }
}

.trainer-certification {
  display: inline-block;
  margin: 0 30px 10px 0;
  text-align: center;

  &__img {
    object-fit: cover;
    border-radius: 6px;
  }

  &__name {
    line-height: 1.25;
    color: $cyan-blue;
  }
}

.trainer-badge {
  font-size: 11px;
  padding: 5px 11px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 13px;
  background: $azure-two;
  color: $white;
  border-radius: 4px;
}

.trainer-info {
  &__head {
    margin-top: pxToRem(-60);
    position: relative;
    z-index: 2;
  }
  &__name {
    font-size: pxToRem(18);
    font-weight: 600;
    color: $dark;
  }
}

.trainer-details__see-all {
  color: $azure-border;
  border-color: $azure-border;
  max-width: 200px;
  width: 100%;
  padding: 0 16px;
  height: 50px;
  font-weight: normal;
  border-radius: pxToRem(30);
  &:hover{
    background-color: $azure-border;
    color: $white;
    border: none;
    transition: .3s;
  }
}
.product__info {
  position: relative;
  color: $white;
  font-weight: normal;
  letter-spacing: normal;

  h2 {
    position: relative;
    font-size: pxToRem(36);
    font-weight: 600;
    line-height: 1.33;
    color: $white;
    padding-bottom: pxToRem(36);
    margin: 0;

  }

  h2::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: " ";
    width: pxToRem(100);
    height: pxToRem(6);
    border-radius: pxToRem(2);
    background-color: $algae-green;
  }

  p {
    margin: pxToRem(30) 0;
    max-width: pxToRem(680);
    font-size: pxToRem(18);
    line-height: 2;
    color: $cyan-blue;
  }

  a {
    margin-top: pxToRem(0);
    margin-bottom: pxToRem(30);
    margin-right: pxToRem(30);
  }

  .stores {
    margin-top: pxToRem(44);
    width: pxToRem(780);
  }

  .exclamation {
    position: absolute;
    top: 0;
    left: pxToRem(-140);
  }
}
.button, .button:hover {
  padding: pxToRem(16) pxToRem(30);
  border-radius: pxToRem(25);
  box-shadow: $default-shadow rgba($color: $algae-green, $alpha: 0.2);
  background-color: $algae-green;
  line-height: 1;
  letter-spacing: pxToRem(0.28);
  margin: 50px auto;
  text-decoration: none;
  color: $white;
}
