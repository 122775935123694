@import "colors";
@import "mixins";

.exercises {
  padding: 0 pxToRem(30);
  max-width: pxToRem(1400);
  width: 100%;
  max-height: pxToRem(750);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: pxToRem(4);
    margin-top: pxToRem(10);
    opacity: 0.5;
    border-radius: pxToRem(4);
    background-color: #d5dee4;

    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb {
    width: pxToRem(4);
    border-radius: pxToRem(4);
    background-color: $azure-three;
    background-clip: content-box;
  }
}

.exercises__title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  padding: pxToRem(30) pxToRem(16);
}

.category-title{
  font-size: pxToRem(16);
  font-weight: 600;
  line-height: 1;
  color: $dark;
  margin: 0 0 pxToRem(10) pxToRem(16);
}
.btn-group-left {
  display: flex;
  font-weight: 700;
  font-size: pxToRem(18);

  .button-reset {
    margin-top: auto;
    color: $azure-three;
    padding-left: 0;
    padding-right: 0;
    font-size: pxToRem(16);
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}
.equipment.btn-outline-primary {
  font-weight: 600;
  font-size: pxToRem(14);
  padding: pxToRem(8) pxToRem(16) !important;
  margin: pxToRem(10) pxToRem(10) 0 0;
  color: $bluey-grey;
  border-color: $bluey-grey;
  border-radius: pxToRem(20);

  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    border-color: $theme-color-1;
    color: $white
  }
}
.category{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: pxToRem(20) 0;
  border-top: 1px solid $cloudy-blue-30;
}
.button__all, .button__all:hover{
  margin: 0 !important;
  border: solid pxToRem(1) transparent;
  border-radius: pxToRem(20);
  color: $white;
  font-size: pxToRem(15);
  line-height: 1.2;
  padding: pxToRem(11) pxToRem(30);
  background-image: linear-gradient(to right, $light-blue, $azure-four);
}

.active__button{
  margin-left: 15px;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .025rem;
  padding: 1.0625rem 1.875rem;
  border: 1px solid transparent;

  &__cancel{
    color: $bluey-grey;
    border-color: $bluey-grey;
    &:hover, :focus{
      color: $white !important;
      background-color: $bluey-grey !important;
    }
  }
  &__apply{
    color: $white;
    background-color: $azure;
    &:hover, :focus{
      color: $white !important;
      background-color: $azure !important;
    }
  }
}

.checkbox{
  margin-top: pxToRem(10)
}
.checkbox input{
  opacity: 0;
  z-index: -1;
}
.checkbox span{
  box-sizing: border-box;
  font-weight: 600;
  font-size: pxToRem(14);
  padding: pxToRem(8) pxToRem(16);
  color: $bluey-grey;
  border: 1px solid $bluey-grey;
  border-radius: pxToRem(20);
  &.select__all{
    color: $azure;
    border-color: $azure;
  }
  &:hover{
    transition: .3s;
    border-color: $theme-color-1;
    background-color: $theme-color-1;
    color: $white
  }
}
.checkbox input[type=checkbox]:checked + span {
  border-color: $theme-color-1;
  background-color: $theme-color-1;
  color: $white
}
.select__all__container{
  min-width: 105px;
}

.after-before-photo-modal {
  width: auto;
  max-width: pxToRem(1196);

  .modal-title {
    font-weight: 500;
    font-size: pxToRem(20);
  }

  .modal-body {
    padding-top: 0;
  }

  .carousel-control-next, .carousel-control-prev {
    width: auto;
    top: pxToRem(40);
    bottom: pxToRem(40);
    &-icon {
      width: pxToRem(48);
      height: pxToRem(48);
    }
  }

  .carousel-control-next {
    right: pxToRem(40);
  }
  .carousel-control-prev {
    left: pxToRem(40);
  }

  .sub-header {
    width: 80%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: pxToRem(16) 0 pxToRem(16) auto;

    button{
      border: none;
      outline: none;
      color: $cyan-blue;
      background-color: transparent;

      &:active, &:focus{
        border: none;
        outline: none;
        cursor: pointer;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .wrapper{
    display: flex;
    border-radius: pxToRem(6);
    width: 80%;

    .photo{
      object-fit: cover;
      width: 100%;
      max-height: pxToRem(700)
    }
    .before{
      border-radius: pxToRem(6) 0 0 pxToRem(6);
    }
    .after{
      border-radius: 0 pxToRem(6) pxToRem(6) 0;
    }
  }
}

.share-modal {
  width: 100%;
  max-width: pxToRem(760);

  .modal {
    &-header {
      padding: pxToRem(40);
      padding-bottom: 0;
      position: relative;
      border-bottom: none;

      .cancel {
        padding: pxToRem(14);

        position: absolute;
        right: pxToRem(26);
        top: pxToRem(20);

        svg {
          width: pxToRem(14);
          height: pxToRem(14);
        }
      }
    }
    &-title {
      font-size: pxToRem(21);
      line-height: pxToRem(24);
      font-weight: 500;
      width: 100%;
      text-align: center;
    }
  }
  .modal-body {
    padding: pxToRem(40);

    @include respond-below(sm) {
      max-height: 90vh;
    }

    .avatar {
      object-fit: cover;
      border-radius: 50%;
    }

    .product {
      &-info {
        margin-bottom: pxToRem(40);
      }
      &-title {
        margin-top: pxToRem(5);
        font-size: pxToRem(18);
        line-height: pxToRem(24);
        font-weight: 500;
        margin-bottom: pxToRem(16);
      }
      &-image {
        object-fit: cover;
        border-radius: pxToRem(6);
      }
      &-author {
        font-size: pxToRem(14);
        line-height: pxToRem(18);
        margin-bottom: pxToRem(12);

        span {
          color: $dark;
        }
      }
      &-description {
        font-size: pxToRem(14);
        line-height: pxToRem(18);
        font-weight: 500;
        padding-right: pxToRem(15);
        margin-bottom: pxToRem(10);
      }
    }

    .link {
      &-title {
        font-size: pxToRem(16);
        line-height: pxToRem(20);
        margin-bottom: pxToRem(16);
      }
      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: pxToRem(13) pxToRem(20);
        border: 1px solid $bluey-grey-30;
        margin-bottom: pxToRem(20);
        border-radius: pxToRem(6);

        .direct {
          font-size: pxToRem(16);
        }
        .copy {
          height: 18px;
          font-size: pxToRem(14);
          line-height: pxToRem(18);
          font-weight: bold;
          padding: 0;
          border-radius: 0;
          background: $white;
          color: $azure-two;
          border: none;
          box-shadow: none !important;
        }
      }
    }

    .share {
      &-row {
        margin-top: pxToRem(-12);
      }
      &-wrapper {
        padding: 0 pxToRem(13);
        flex:  0 0 auto;
        margin-top: pxToRem(12);
      }
      &-icon {
        border-radius: 50%;
        margin: auto;
      }
      &-label {
        display: block;
        margin-top: pxToRem(10);
        text-align: center;
        font-size: pxToRem(12);
        font-weight: 500;
      }
    }
  }
}

.auth__modal{
  width: 100vw;
  max-width: 500px;
}

.account-blocked-modal {
  padding: pxToRem(40) pxToRem(60);

  .title {
    font-size: pxToRem(20);
    font-weight: 500;
  }

  .text {
    color: $cyan-blue;
    text-align: center;
    font-size: pxToRem(16);
    max-width: pxToRem(290);

    margin-bottom: pxToRem(40);
  }

  .btn {
    width: pxToRem(168);
  }
}

.second-verify{

  .title{
    font-size: pxToRem(24);
    line-height: pxToRem(48);
    text-align: center;
    margin-bottom: pxToRem(20);
  }

  .text{
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    margin-bottom: pxToRem(8);

    text-align: start !important;
  }

  .second-verify-form{

    .form-label{
      font-size: pxToRem(12);
      margin-bottom: 0;
    }

    .form-control{
      padding-top: 0;
      padding-bottom: 0;


    }
  }

}

.report-photo-modal {
  width: pxToRem(475);
  padding: pxToRem(40);

  .modal-header {
    border: none;
  }

  .modal-body {
    border-top: none;
  }

  .modal-footer {
    border-top: none;
    padding: pxToRem(24) pxToRem(40) pxToRem(40);
  }

  .post-question-body {
    padding: 0 pxToRem(40);

    .form-label {
      display: flex;
      align-items: flex-end;
      text-align: center;

      font-size: pxToRem(16);
      line-height: pxToRem(24);
      color: $cyan-blue
    }
  }

  textarea {
    padding: pxToRem(15) pxToRem(20);
    font-size: pxToRem(16);
    line-height: 1;
    height: pxToRem(150);
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid $bluey-grey-30;
    outline: initial !important;
    box-shadow: none !important;
    resize: none;

    &::placeholder{
      font-size: pxToRem(16);
      line-height: 1;
      color: $bluey-grey;
    }
  }

  .modal-title {
    display: flex;
    margin: 0 auto;
    font-weight: 500;
    font-size: pxToRem(20);
    line-height: pxToRem(36);
  }

  .sub-header {
    display: flex;
    justify-content: center;
    margin-top: pxToRem(16);
    font-size: pxToRem(16);
    color: $cyan-blue;
  }

  .btn-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      border-radius: pxToRem(25);
      border: $default-border;
      font-size: pxToRem(16);
      font-weight: 500;
      line-height: 1;
      text-align: center;
      padding: pxToRem(16) pxToRem(64);

      &:active, &:focus {
        outline: none;
        box-shadow: none;
      }

      &-primary {
        background-image: linear-gradient(to right, $light-blue, $azure-four);
      }
    }
  }
}

.report-modal {
  width: pxToRem(355);

  &__title {
    font-size: pxToRem(18);
    line-height: 1;
    font-weight: 600;
    padding: pxToRem(20) 0;
  }

  &__form-group {
    margin-bottom: pxToRem(30);
  }

  &__label {
    font-size: pxToRem(16);
    line-height: 1;
    color: $bluey-grey;
    margin-bottom: pxToRem(14);
  }

  &__select {
    line-height: 1.13;
    font-size: pxToRem(16);
  }

  &__textarea {
    padding: pxToRem(15) pxToRem(20);
    font-size: pxToRem(16);
    line-height: 1;
    color: $bluey-grey;
    height: pxToRem(120);
    width: 100%;
    border-radius: 0.375rem;
    border: 1px solid $bluey-grey-30;
    outline: initial !important;
    box-shadow: none !important;
    resize: none;
    &::placeholder{
      font-size: pxToRem(16);
      line-height: 1;
      color: $bluey-grey;
    }
  }

  .react-select {
    font-size: pxToRem(16);
    line-height: 1.13;

    &__value-container {
      padding: pxToRem(16) pxToRem(14) !important;
      height: pxToRem(48);
    }
  }
}
.report-x{
  position: fixed;
  top: calc(50% - 250px);
  right: calc(50% - 170px);
}
.report-button{
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: .025rem;
  margin-top: 0 !important;
  padding: 17px 30px !important;
  border: 1px solid transparent;
  &__cancel{
    color: $bluey-grey;
    border-color: $bluey-grey;
    &:hover, :focus{
      color: $bluey-grey !important;
      background-color: $white !important;
    }
  }
  &__submit{
    color: $white;
    background-color: $azure;
    &:hover, :focus{
      color: $white !important;
      background-color: $azure !important;
    }
  }
}

.container_button_type {
   margin: pxToRem(30) 0;
   width: 100%;

  input{
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

   .custom-radio .custom-control-input:checked ~ .custom-control-label{
     border: pxToRem(2) solid $azure-two;
     background-color: rgba($azure-two, .05);
     font-weight: 600;
   }

   .custom-control{
     margin-bottom: pxToRem(22);
     padding: 0;
   }

   .custom-control-label {
     width: 100%;
     height: pxToRem(60);
     border: $default-border;
     border-radius: pxToRem(6);
     background-color: $white;

     color: $dark;
     font-weight: 400;
     font-size: pxToRem(16);
     line-height: pxToRem(24);

     display: flex;
     align-items: center;
     justify-content: center;

     &:after, &:before{
       display: none;
     }
   }

   .custom-control-label:hover{
     border: pxToRem(2) solid $azure-two;
     background-color: rgba($azure-two, .05);
     font-weight: 600;
   }
   .custom-control:last-child{
     margin-bottom: 0;
   }
 }

.forgot-password-modal{
  width: pxToRem(460);

  .title{
    font-size: pxToRem(24);
    line-height: pxToRem(48);
  }

  .label{
    font-size: pxToRem(16);
    line-height: pxToRem(18);
    justify-content: center !important;
    text-align: center;
    color: $dark;
    margin-bottom: pxToRem(32);

    &-done{
      font-size: pxToRem(20);
      line-height: pxToRem(36);
      max-width: pxToRem(330);
      display: flex;
      text-align: center;
      margin: 0 auto;
    }
  }

  .email{
    text-align: center;
    font-weight: 600;
    line-height: pxToRem(36);
    font-size: pxToRem(20);
  }

  .btn-panel{
    width: 100%;
    display: flex;
    margin-top: pxToRem(40);

    .btn{
      min-width: pxToRem(150);
    }
  }
}
