@import "mixins";
@import "colors";

$font-size-default: 16px;
$font-size-viewport: 100vw * $font-size-default / 1920px;
$main-menu-item-height: pxToRem(60);

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: pxToRem(6);

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: $vermillion;

$dashboard-padding: pxToRem(90);
$default-shadow: 0 pxToRem(10) pxToRem(20) 0;
$small-shadow: 0 pxToRem(10) pxToRem(15) 0;

$default-border: pxToRem(1) solid $icon-borderline;

:root {
  --main-indend-x: #{pxToRem(75)};
  --main-menu-width: #{pxToRem(320)};
  --main-menu-width-collapse: #{pxToRem(70)};
  --min-content-width: #{pxToRem(1440)};

  --navbar-height: #{pxToRem(80)};

  --main-menu-item-height: #{pxToRem(60)};

  @include respond-below(xl) {
    --navbar-height: 90px;
  }

  @include respond-below(lg) {
    --main-indend-x: #{pxToRem(45)};
  }

  @include respond-below(sm) {
    --main-indend-x: #{pxToRem(15)};
    --navbar-height: 70px;
  }
}

$activity-index: 700;
$full-margin: calc(var(--main-indend-x) + #{pxToRem(15)});
$full-margin-negative: calc(#{$full-margin} * -1);
