@import "colors", "mixins";

.landing-search {
  width: 67%;
  border-radius: 30px;
  background: $white;
  display: flex;
  align-items: center;
  height: pxToRem(50);
  font-size: pxToRem(16);
  margin-left: pxToRem(80);

  input {
    width: 100%;
    min-height: pxToRem(42);
    border: none !important;
    color: $bluey-grey;
    outline: none;
    padding-left: pxToRem(25);
    background: transparent;

    &::placeholder {
      color: $bluey-grey;
    }
  }

  @include respond-below(md) {
    margin-left: pxToRem(16);
  }

  .btn--search {
    height: pxToRem(46);
    padding: 0 !important;
    font-size: pxToRem(18) !important;
    margin-right: pxToRem(2);
    border-radius: pxToRem(30) !important;
    max-width: pxToRem(160);
    width: 100%;
    background-image: linear-gradient(to right, $light-blue, $azure-four);
  }

  .react-select {
    height: 100%;
    width: 100%;
    max-width: pxToRem(210);
    font-size: pxToRem(16);

    &__dropdown-indicator {
      padding-right: 22px;
    }

    &__placeholder {
      color: $bluey-grey;
    }

    &__control {
      height: 100%;
      background: $ice-blue !important;
      border-radius: 30px 0 0 30px !important;
      border: none !important;
      padding-left: 25px;
    }

    &__value-container {
      padding: 0 !important;
      background: $ice-blue !important;
    }

    &__option {
      padding-left: 25px;

      &--is-focused, &--is-selected {
        background: $ice-blue !important;
        color: $dark !important;
      }
    }
    &__menu {
      border: none !important;
      border-radius: 6px !important;
      margin-top: 8px !important;
    }

    &__single-value {
      padding-top: 0;
    }
  }
}