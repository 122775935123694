@import "mixins";
@import "colors";
@import "variables";

.alert {
  border-radius: 0;
}

.alert-primary {
  color: $theme-color-1;
  background-color: rgba($theme-color-1, 0.2);
  border-color: rgba($theme-color-1, 0.1);
}

.alert-secondary {
  color: $theme-color-2;
  background-color: rgba($theme-color-2, 0.2);
  border-color: rgba($theme-color-2, 0.1);
}

.alert-success {
  color: $success-color;
  background-color: rgba($success-color, 0.2);
  border-color: rgba($success-color, 0.1);
}

.alert-info {
  color: $info-color;
  background-color: rgba($info-color, 0.2);
  border-color: rgba($info-color, 0.1);
}

.alert-warning {
  color: $warning-color;
  background-color: rgba($warning-color, 0.2);
  border-color: rgba($warning-color, 0.1);
}

.alert-danger {
  color: $error-color;
  background-color: rgba($error-color, 0.2);
  border-color: rgba($error-color, 0.1);
}

.alert-dismissible .close {
  text-shadow: initial;
}

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem;
}

div[data-notify="container"] {
  padding: 18px;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.popover, .tooltip {
  top: -95px !important;

  @include respond-below(xl) {
    top: -85px !important;
  }

  @include respond-below(lg) {
    top: -75px !important;
  }

  @include respond-below(sm) {
    top: -65px !important;
  }
}

.popover {
  border-radius: $border-radius;
  background-color: $foreground-color;
  border-color: $separator-color;

  .popover-body {
    color: $primary-color;
    padding: 0.5rem 0.75rem;
  }

  &-primary {
    .popover-body {
      background-color: $theme-color-1;
      font-size: pxToRem(12);
      line-height: 1.5;
      color: $white;
      padding-right: pxToRem(20);

      .ps__thumb-y {
        background-color: $white;
      }

      .scrollbar-container {
        max-height: pxToRem(160);

      }
    }

    .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
      border-top-color: $theme-color-1;
    }

    .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
      border-bottom-color: $theme-color-1;
    }
  }
}

.popover-header {
  background-color: transparent;
  border-bottom: initial;
}

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: $primary-color;
  background-color: $foreground-color;
  border-radius: 0.1rem;
  border: 1px solid $separator-color;
}

.tooltip.show {
  opacity: 1;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: $separator-color;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: $foreground-color;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: $separator-color;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: $foreground-color;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: $separator-color;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: $foreground-color;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: $separator-color;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: $foreground-color;
}

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: $separator-color;
}

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: $foreground-color;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: $separator-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: $foreground-color;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: $separator-color;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: $foreground-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: $separator-color;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: $foreground-color;
}

.search-sm {
  position: relative;

  input {
    background: none;
    outline: initial !important;
    border: 1px solid $secondary-color;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    color: $primary-color;
  }

  &:after {
    content: "\e090";
    font-size: 14px;
    border-radius: 10px;
    color: $secondary-color;
    position: absolute;
    width: 22px;
    right: 4px;
    text-align: center;
    cursor: pointer;
    top: 2px;
  }
}

.notification {
  border-radius: $border-radius;
  @include depth(1);
  padding: 25px 25px 25px 25px;
}

.notification:before {
  content: "";
  display: none;
}

.notification-primary {
  color: $theme-color-1;
  background-color: $foreground-color;
  border: 1px solid $theme-color-1;
  opacity: 1;

  .notification-message {
    .title {
      color: $theme-color-1;
    }

    .message {
      color: $primary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $theme-color-1;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-secondary {
  color: $theme-color-2;
  background-color: $foreground-color;
  border: 1px solid $theme-color-2;
  opacity: 1;

  .notification-message {
    .title {
      color: $theme-color-2;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $theme-color-2;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-info {
  color: $info-color;
  background-color: $foreground-color;
  border: 1px solid $info-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $info-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $info-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-success {
  color: $success-color;
  border: 1px solid $algae-green;
  opacity: 1;
  padding: pxToRem(15) pxToRem(32) pxToRem(15) pxToRem(60);
  background-color: $foreground-color;

  &::before {
    content: "";
    width: pxToRem(16);
    height: pxToRem(16);
    display: inline-block;
    background: url("../icons/is-success-alert.svg") no-repeat center center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: pxToRem(30);
  }

  .notification-message {
    .title {
      color: $success-color;
    }

    .message {
      color: $dark;
      font-size: pxToRem(14);
      font-weight: 500;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $success-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-warning {
  color: $warning-color;
  background-color: $foreground-color;
  border: 1px solid $warning-color;
  opacity: 1;

  .notification-message {
    .title {
      color: $warning-color;
    }

    .message {
      color: $secondary-color;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $warning-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}

.notification-error {
  $background: rgba(231, 59, 114, 0.05);
  color: $error-color;
  background: $background;
  background: linear-gradient(0deg, $background, $background), #FFFFFF;
  border: pxToRem(1) solid $error-color;
  padding: pxToRem(15) pxToRem(32) pxToRem(15) pxToRem(48);
  opacity: 1;

  &::before {
    content: "";
    width: pxToRem(16);
    height: pxToRem(16);
    display: inline-block;
    background: url("../icons/ic-error-alert.svg") no-repeat center center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: pxToRem(24);
  }

  .notification-message {
    .title {
      color: $error-color;
    }

    .message {
      font-size: pxToRem(14);
      font-weight: 500;
      color: $dark;
    }
  }

  &.filled {
    color: $foreground-color;
    background-color: $error-color;

    .notification-message {
      .title {
        color: $foreground-color;
      }

      .message {
        color: $foreground-color;
      }
    }
  }
}
