@import "styles/colors";
.wrapper{
  z-index: 1050;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(0.125rem) saturate(0.8);
  background-color: $background-color;
  width: 100vw;
  height: 100vh;
}
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba($theme-color-1, 0.2);
  border-radius: 50%;
  border-top-color: $theme-color-1;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.none{
  display: none;
}