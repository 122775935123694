@import "styles/colors";
@import "styles/mixins";
.modal{
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(55, 67, 83, 0.8);
  backdrop-filter: blur(5px);

  opacity: 0;
  pointer-events: none;
  transition: .5s;
  z-index: 5;
}
.position__center{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.75rem;
}
.modal__content{
  background-color: $white;
  border-radius: 6px;
}
.active{
  opacity: 1;
  pointer-events: initial;
}
.modal__head{
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-family: inherit;
}

.signUp{
  font-size: 1.75rem !important;
}

.modal__footer {
  align-items: center;
  font-size: .85rem;
  margin: 30px 0 10px;
  color: $cyan-blue;
}
.btn__link{
  margin-left: 24px;
  font-weight: 600;
  color: $azure;
  &:hover{
    color: $azure;
  }
}
.btn{
  text-align: center;
  border: solid pxToRem(1) transparent;
  border-radius: pxToRem(24);
  color: $white;
  font-size: pxToRem(16);
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: .05rem;
  padding: .75rem 2.6rem .6rem;
  background-image: linear-gradient(to right, $light-blue, $azure-four);
}
@mixin label {
  display: flex;
  align-items: center;
  margin-left: 4px;
  font-size: .8rem;
  font-weight: 400;
  color: $cyan-blue;
}
.checkbox__label{
  @include label;
  color: $dark;
  font-size: pxToRem(14);
}

.checkbox__label::before{
  @include checkbox-icon(1px solid $azure-three)
}
.checkbox__label__with__link{
  @include label;
  justify-content: center;
  a{
    font-weight: 600;
    color: $azure !important;
    &:hover{
      text-decoration: none !important;
      cursor: pointer;
    }
  }
}
.checkbox__label__with__link::before{
  @include checkbox-icon(1px solid $gray);
  margin-bottom: 20px
}
input:checked+.checkbox__label::before,
input:checked+.checkbox__label__with__link::before
{
  background-size: pxToRem(14);
  background-image: url("../../icons/input/ic-checkbox-active.svg");
  border: none
}

.checkbox__group{
  display: flex;
  align-items: center;
}

.filter__checkbox {
  display: block;
  box-sizing: border-box;
  color: $bluey-grey;

  input{
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}

.form__group{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}
.forgot__pass{
  cursor: pointer;
  color: $azure;
  font-weight: 500;
  padding: 0;
  font-size: 1em;
}